export const ADMIN_FROALA_TOOLBAR = {
  moreText: {
    buttons: ['bold', 'italic', 'underline', 'strikeThrough'],
    align: 'left',
    buttonsVisible: 4
  },

  moreRich: {
    buttons: ['insertLink', 'insertImage'],
    align: 'left',
    buttonsVisible: 2
  },

  moreMisc: {
    buttons: ['undo', 'redo'],
    align: 'right',
    buttonsVisible: 2
  }
};
